<template>
    <div>   
		<div class="card border-primary round h-100">
			<div class="card-body p-3">
				<h4 class="text-center"><i class="fa fa-sort-amount-down"></i> &nbsp; Nego Harga</h4>
				<hr />
				<ul class="nav nav-pills w-100" id="pills-tab" role="tablist">
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a class="nav-link border border-info rounded-lg shadow-sm active" data-toggle="tab" href="#menunggu-persetujuan-tab" role="tab" @click="get_negoList(0)">
							<div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_nego_count[0] > 0">{{ jumlah_nego_count[0] }}</span></div>
							<i class="far fa-clock fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Menunggu Persetujuan</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#setuju-nego-tab" role="tab" @click="get_negoList(1)">
							<div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_nego_count[1] > 0">{{ jumlah_nego_count[1] }}</span></div>
							<i class="far fa-check-circle fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Nego Disetujui</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#tolak-nego-tab" role="tab" @click="get_negoList(2)">
							<!-- <div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_nego_count[2] > 0">{{ jumlah_nego_count[2] }}</span></div> -->
							<i class="far fa-times-circle fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Nego Ditolak</h6>
						</a>
					</li>
					<li class="nav-item text-center w-xs-100 w-sm-100 w-md-50 w-lg-25 w-xl-25 px-2 py-1">
						<a class="nav-link border border-info rounded-lg shadow-sm" data-toggle="tab" href="#riwayat-nego-tab" role="tab" @click="get_negoList(3)">
							<!-- <div class="position-relative ml-2"><span class="badge badge-float-2 badge-pill badge-danger badge-lg" v-if="jumlah_nego_count[3] > 0">{{ jumlah_nego_count[3] }}</span></div> -->
							<i class="far fa-handshake fa-2x mt-1"></i>
							<h6 class="mb-1 d-flex justify-content-center align-items-center" style="height: 40px; overflow: hidden;">Riwayat Nego</h6>
						</a>
					</li>
				</ul>
				<div class="tab-content border rounded-lg shadow px-4 py-3 mt-2">
					<div class="tab-pane fade show active px-3 py-2" id="menunggu-persetujuan-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_nego_count[0] <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="negoList" v-else>
								<div class="col-md-12 mb-3" v-for="(bid, index) in list_nego" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-lg-12">
													<h6 class="text-dark mb-0">Nego Harga</h6>
													<p class="text-muted mb-0">{{ bid.bid_date | momentFormatID }}</p>
													<tr>
														<td>Kode</td>
														<td class="px-2"> : </td>
														<td><span class="text-info">{{ bid.bid_code }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2"> : </td>
														<td><span v-if="bid.bid_status == 0" class="text-info">Menunggu Persetujuan Admin</span></td>
													</tr>
													<hr class="mx-0 my-2 w-75" />
												</div>
												<div class="col-lg-3 px-2">
													<img v-lazy="productImage(bid.images[0].prod_image)" @error="imageLoadError" class="card-img-top" :alt="bid.images[0].prod_name" />
												</div>
												<div class="col-lg-9 text-left px-2">
													<router-link :to="'/product-detail/'+bid.images[0].prod_id+'-'+bid.images[0].prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
														<h6 class="card-text text-secondary mb-0">{{ bid.images[0].prod_name | capitalize  }}</h6>
														<p class="text-muted mb-0">{{ bid.images[0].prod_summary | str_limit(50) }}</p>
													</router-link>
													<p class="text-muted mb-0">Harga Awal: {{ bid.images[0].prod_price | formatCurrency('IDR') }}</p>
												</div>
											</div>
										</div>
										<div class="col-md-4 my-2">
											<h6 class="mb-0">Harga Nego Anda</h6>
											<h5 class="text-success">{{ bid.bid_price | formatCurrency('IDR') }}</h5>									
											<button class="btn btn-outline-warning w-100 my-1" @click="prepare_Nego(bid.bid_code, bid.bid_price)"><i class="fa fa-edit"></i> &nbsp; Ubah Nego</button>
											<button class="btn btn-outline-danger w-100 my-1" @click="cancelNego(bid.bid_code, bid.images[0].prod_name)"><i class="fa fa-trash"></i> &nbsp; Batalkan Nego</button>
										</div>
									</div>
								</div>
							</div>
							<!-- Modal ubah nego -->
							<div class="modal fade" id="modal-ubah-nego" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
								<div class="modal-dialog modal-dialog-centered modal" role="document">
									<div class="modal-content">
										<div class="modal-header">
											<h5 class="modal-title" id="exampleModalLongTitle">Ubah Nego Harga</h5>
											<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
										</div>
										<form @submit.prevent="ubah_negoHarga">
											<div class="modal-body px-4">
												<div class="text-center my-3" v-if="is_refreshDetail">
													<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
												</div>
												<div class="container px-5" v-else>
													<h6>Nego Harga semula <span class="text-success">{{ this.ubah_detail_bid | formatCurrency('IDR') }}</span></h6>
													<div class="form align-items-center">
														<div class="input-group">
															<div class="input-group-prepend">
																<div class="input-group-text bg-light">
																	Rp
																</div>	
															</div>
															<input type="text" v-model.trim="harga_negoUbah" class="form-control" placeholder="Masukan harga Nego baru di sini!" v-formatIDR required />
														</div>
													</div>
													<p class="mb-0 mt-1 text-muted">
														Untuk nego harga, masukkan harga yang anda inginkan kemudian tunggu persetujuan dari admin.
													</p>
												</div>
											</div>
											<div class="modal-footer" v-if="!is_refreshDetail">
												<button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
												<button type="submit" class="btn btn-primary">Ubah</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="setuju-nego-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_nego_count[1] <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="negoList" v-else>
								<div class="col-md-12 mb-3" v-for="(bid, index) in list_nego" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-lg-12">
													<h6 class="text-dark mb-0">Nego Harga</h6>
													<p class="text-muted mb-0">{{ bid.bid_date | momentFormatID }}</p>
													<tr>
														<td>Kode</td>
														<td class="px-2"> : </td>
														<td><span class="text-info">{{ bid.bid_code }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2"> : </td>
														<td><span v-if="bid.bid_status == 1" class="text-info">Nego Harga Disetujui</span></td>
													</tr>
													<hr class="mx-0 my-2 w-75" />
												</div>
												<div class="col-lg-3 px-2">
													<img v-lazy="productImage(bid.images[0].prod_image)" @error="imageLoadError" class="card-img-top" :alt="bid.images[0].prod_name" />
												</div>
												<div class="col-lg-9 text-left px-2">
													<router-link :to="'/product-detail/'+bid.images[0].prod_id+'-'+bid.images[0].prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
														<h6 class="card-text text-secondary mb-0">{{ bid.images[0].prod_name | capitalize  }}</h6>
														<p class="text-muted mb-0">{{ bid.images[0].prod_summary | str_limit(50) }}</p>
													</router-link>
													<p class="text-muted mb-0">Harga Awal: {{ bid.images[0].prod_price | formatCurrency('IDR') }}</p>
												</div>
											</div>
										</div>
										<div class="col-md-4 my-2">
											<h6 class="mb-0">Harga Nego Anda</h6>
											<h5 class="text-success">{{ bid.bid_price | formatCurrency('IDR') }}</h5>
											<button class="btn btn-outline-success w-100" @click="plusKeranjang(bid.bid_code)" v-if="bid.bid_status == 1"><i class="fa fa-cart-plus"></i> Keranjang</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="tolak-nego-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_nego_count[2] <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="negoList" v-else>
								<div class="col-md-12 mb-3" v-for="(bid, index) in list_nego" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-lg-12">
													<h6 class="text-dark mb-0">Nego Harga</h6>
													<p class="text-muted mb-0">{{ bid.bid_date | momentFormatID }}</p>
													<tr>
														<td>Kode</td>
														<td class="px-2"> : </td>
														<td><span class="text-info">{{ bid.bid_code }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2"> : </td>
														<td><span v-if="bid.bid_status == 2" class="text-info">Nego Harga Ditolak</span></td>
													</tr>
													<hr class="mx-0 my-2 w-75" />
												</div>
												<div class="col-lg-3 px-2">
													<img v-lazy="productImage(bid.images[0].prod_image)" @error="imageLoadError" class="card-img-top" :alt="bid.images[0].prod_name" />
												</div>
												<div class="col-lg-9 text-left px-2">
													<router-link :to="'/product-detail/'+bid.images[0].prod_id+'-'+bid.images[0].prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
														<h6 class="card-text text-secondary mb-0">{{ bid.images[0].prod_name | capitalize  }}</h6>
														<p class="text-muted mb-0">{{ bid.images[0].prod_summary | str_limit(50) }}</p>
													</router-link>
													<p class="text-muted mb-0">Harga Awal: {{ bid.images[0].prod_price | formatCurrency('IDR') }}</p>
												</div>
											</div>
										</div>
										<div class="col-md-4 my-2">
											<h6 class="mb-0">Harga Nego Anda</h6>
											<h5 class="text-success">{{ bid.bid_price | formatCurrency('IDR') }}</h5>
											<router-link :to="'/product-detail/'+bid.images[0].prod_id+'-'+bid.images[0].prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none" v-if="bid.bid_status == 2">
												<button class="btn btn-outline-secondary w-100"><i class="fa fa-info-circle"></i> Nego Ulang</button>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="tab-pane fade px-3 py-2" id="riwayat-nego-tab" role="tabpanel">
						<div class="text-center my-3" v-if="isRefresh">
    					  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    					</div>
						<div v-else>
							<div class="text-center" v-if="jumlah_nego_count[1] <= 0">
								<h6 class="mb-0">Transaksi ini masih kosong</h6>
								<router-link to="/product-list?type=product&q=Semua%20Produk" class="text-info text-decoration-none">Belanja sekarang!</router-link>
							</div>
							<div class="row max-content-user customScroolBar" id="negoList" v-else>
								<div class="col-md-12 mb-3" v-for="(bid, index) in list_nego" :key="index">
									<div class="row border round d-flex align-items-center p-3 border-info shadow-sm">
										<div class="col-lg-8">
											<div class="row d-flex align-items-center">
												<div class="col-lg-12">
													<h6 class="text-dark mb-0">Nego Harga</h6>
													<p class="text-muted mb-0">{{ bid.bid_date | momentFormatID }}</p>
													<tr>
														<td>Kode</td>
														<td class="px-2"> : </td>
														<td><span class="text-info">{{ bid.bid_code }}</span></td>
													</tr>
													<tr>
														<td>Status</td>
														<td class="px-2"> : </td>
														<td><span v-if="bid.bid_status == 3" class="text-info">Nego Selesai</span></td>
													</tr>
													<hr class="mx-0 my-2 w-75" />
												</div>
												<div class="col-lg-3 px-2">
													<img v-lazy="productImage(bid.images[0].prod_image)" @error="imageLoadError" class="card-img-top" :alt="bid.images[0].prod_name" />
												</div>
												<div class="col-lg-9 text-left px-2">
													<router-link :to="'/product-detail/'+bid.images[0].prod_id+'-'+bid.images[0].prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
														<h6 class="card-text text-secondary mb-0">{{ bid.images[0].prod_name | capitalize  }}</h6>
														<p class="text-muted mb-0">{{ bid.images[0].prod_summary | str_limit(50) }}</p>
													</router-link>
													<p class="text-muted mb-0">Harga Awal: {{ bid.images[0].prod_price | formatCurrency('IDR') }}</p>
												</div>
											</div>
										</div>
										<div class="col-md-4 my-2">
											<h6 class="mb-0">Harga Nego Anda</h6>
											<h5 class="text-success">{{ bid.bid_price | formatCurrency('IDR') }}</h5>
											<router-link :to="'/product-detail/'+bid.images[0].prod_id+'-'+bid.images[0].prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none" v-if="bid.bid_status == 3">
												<button class="btn btn-outline-secondary w-100"><i class="fa fa-info-circle"></i> Nego Ulang</button>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12 w-100 text-center mt-1">
							<hr />
							<b-pagination aria-controls="negoList" v-model="current_page" :per-page="per_page" :total-rows="jumlah_nego" first-number last-number align="center" size="md"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuBargaining',
	data() {
		return {
			nego: [],
			product_nego: [],
			jumlah_nego: 0,
			jumlah_nego_count: [],
			isRefresh: false,
			is_refreshDetail: false,
			ubah_detail_id: 0,
			ubah_detail_bid: 0,
			harga_negoUbah: '',
			current_page: 1,
			per_page: 10,
		}
	},
	methods: {
		count_negoList() {
			for(let i=0; i<=3; i++) {
				window.axios.get('/nego?bid_status='+ i, {
						headers: {
							"token": this.$store.getters.token_member
						}
					}
				)
				.then(response => {
					this.jumlah_nego_count[i] = response.data.data.length
				})
				.catch(error => {
					console.log(error)
				});
			}
		},
		get_negoList(id) {
			this.isRefresh = true
			this.jumlah_nego = 0
			this.harga_negoUbah = ''
			this.ubah_detail_id = ''
			this.ubah_detail_bid = ''
			window.axios.get('/nego?bid_status=' + id, {
       		        headers: {
       		            "token": this.$store.getters.token_member
       		        }
       		    }
       		)
       		.then(response => {
				let temp = []
				temp = response.data.data
				this.jumlah_nego = temp.length

				// get Image Nego
				if(id == 0 && this.jumlah_nego_count[0] > 0) {
					for(let i=0; i<this.jumlah_nego_count[0]; i++) {
						let array_list = []
						window.axios.get('/catalogue/detail/'+ temp[i].bid_prod_id, {
								headers: {
									"token": this.$store.getters.token_member
								}
							}
						)
						.then(response => {
							array_list.push(response.data.data)
						})
						.catch(error => {
							console.log(error)
						});
						temp[i]['images'] = array_list
					}
				}
				else if(id == 1 && this.jumlah_nego_count[1] > 0) {
					for(let i=0; i<this.jumlah_nego_count[1]; i++) {
						let array_list = []
						window.axios.get('/catalogue/detail/'+ temp[i].bid_prod_id, {
								headers: {
									"token": this.$store.getters.token_member
								}
							}
						)
						.then(response => {
							array_list.push(response.data.data)
						})
						.catch(error => {
							console.log(error)
						});
						temp[i]['images'] = array_list
					}
				}
				else if(id == 2 && this.jumlah_nego_count[2] > 0) {
					for(let i=0; i<this.jumlah_nego_count[2]; i++) {
						let array_list = []
						window.axios.get('/catalogue/detail/'+ temp[i].bid_prod_id, {
								headers: {
									"token": this.$store.getters.token_member
								}
							}
						)
						.then(response => {
							array_list.push(response.data.data)
						})
						.catch(error => {
							console.log(error)
						});
						temp[i]['images'] = array_list
					}
				}
				else if(id == 3 && this.jumlah_nego_count[3] > 0) {
					for(let i=0; i<this.jumlah_nego_count[3]; i++) {
						let array_list = []
						window.axios.get('/catalogue/detail/'+ temp[i].bid_prod_id, {
								headers: {
									"token": this.$store.getters.token_member
								}
							}
						)
						.then(response => {
							array_list.push(response.data.data)
						})
						.catch(error => {
							console.log(error)
						});
						temp[i]['images'] = array_list
					}
				}
				this.nego = temp
				this.isRefresh = false
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		prepare_Nego(id, price) {
			this.is_refreshDetail = true
			this.harga_negoUbah = price
			this.ubah_detail_id = id
			this.ubah_detail_bid = price

			$('#modal-ubah-nego').modal()
			this.is_refreshDetail = false
		},
		ubah_negoHarga() {
			let formData = new FormData()
			formData.append('_method', 'patch')
			formData.append('bid_code', this.ubah_detail_id)
			formData.append('nego_price', this.harga_negoUbah.replace(/\D/g,''))

			window.axios.post('/nego', formData, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
					}
				}
			)
			.then((response) => {
				this.get_negoList(0)
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Nego Harga berhasil diubah</p>',
					icon: 'success',
					timer: 4000,
					showConfirmButton: false,
				});
			})
			.catch((error) => {
				this.get_negoList(0)
				this.$swal({
					title: 'Oops!', 
					html: '<p>Terjadi Kesalahan</p>',
					icon: 'error',
					timer: 2500,
					showConfirmButton: false,
				});
			})
			$('#modal-ubah-nego').modal('hide')
		},
		cancelNego(id, name) {
			this.$swal({
				title: 'Yakin?', 
				html: '<p>ingin membatalkan Nego untuk produk <br/>'+ name +'</p>',
				icon: 'warning',
				timer: 7000,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ya, batalkan!'
			})
			.then((result) => {
				if (result.isConfirmed) {
					window.axios.delete('/nego/'+ id, {
							headers: {
								"token": this.$store.getters.token_member
							}
						}
					)
					.then((response) => {
						this.count_negoList()
						this.get_negoList(0)

						this.$swal({
							title: 'Berhasil', 
							html: '<p>Nego Harga berhasil dibatalkan</p>',
							icon: 'success',
							timer: 3000,
							showConfirmButton: false,
						});
					})
					.catch((error) => {
						this.count_negoList()
						this.get_negoList(0)

						this.$swal({
							title: 'Oops!', 
							html: '<p>Terjadi Kesalahan</p>',
							icon: 'error',
							timer: 2500,
							showConfirmButton: false,
						});
					})
				}
			});
		},
		plusKeranjang(id) {
			let qty = 1
			let formData = new FormData();

			formData.append('bid_code', id);
			formData.append('prod_qty', qty);

			window.axios.post('/nego/cart', formData, {
                    headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
                    }
                }
            )
			.then(response => {
				this.$swal({
					title: 'Berhasil', 
					html: '<p>Produk telah masuk ke dalam Keranjang</p>',
					icon: 'success',
					timer: 5000,
					showConfirmButton: false,
				});
				this.$router.push('/member/shopping-cart')
				window.NavbarDirtyAccess.get_productKeranjang()
			})
			.catch(error => {
				console.log(error)
			});
		}
	},
	computed: {
		list_nego : function() {
			let position = $(document).scrollTop()
			$('html, body').stop().animate({
				scrollTop: 83
			}, 1000, 'easeInOutExpo')

			return this.nego.slice(
				(this.current_page - 1) * this.per_page, this.current_page * this.per_page,
			)
		}
	},
	created() {
		$(document).scrollTop(0)
		this.count_negoList()
		this.get_negoList(0)
	}
}

</script>